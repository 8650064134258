import theme from '../default/index.js';

/**
 * This is an override theme file for the EAU Nurses site
 */

import Color from 'color';

const primaryColor = Color('#dfac38'),
    secondaryColor = Color('#FFE4B1');

const color = {
    darkest: 'var(--color-secondary-dark)',
    lightest: '#fdfdfe',

    primary: primaryColor.hex(),
    'primary-light':  '#fffaf0', //'primaryColor.lighten(0.15).hex(),
    'primary-dark': primaryColor.darken(0.25).hex(),
    secondary: secondaryColor.hex(),

    // todo check why these are the base for our typography
    'secondary-light': '#f4f5f7',
    'secondary-dark': '#152443',

    // todo rename to secondary colors
    'alt-light': '#E2F3FF',
    'alt-dark': '#2453AF',
};

const flags = {
    // Quote
    '--quote-align': 'center',
    '--quote-font-weight': 'normal',

    // Asset sizes
    '--desktop-quote-width': '50%',
    '--desktop-video-width': '70%',

    // Colors
    '--home-bg-color': 'var(--color-primary-light)',
    '--nav-bg-color': 'var(--color-primary-light)',
    '--hero-bg-color': 'var(--color-primary-light)',
    '--footer-bg-color': 'var(--color-secondary)',

};

export default {
    ...theme,
    ...flags,
    color
};
